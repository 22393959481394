<template>
  <div v-if="states.dining" class="dining">
    <Header
      :info="states.dining"
      :actions="actions"
      :menu="states.dining.menus.length > 0"
      :url="states.dining.reservationUrl"
      @showMenu="
        () => {
          openMenu = true
        }
      "
    />
    <div v-if="states.eventList.length" class="event-section">
      <DecoTitle title="主題精選" />
      <EventSwiper :eventList="states.eventList" chip="2" :tab="storeId" />
    </div>
    <div class="dining-content" id="tab-container">
      <div v-html="states.dining.information" class="desc"></div>
    </div>
    <transition name="fade">
      <BottomModal
        :open="openMenu"
        title="線上菜單"
        @close="
          () => {
            openMenu = false
          }
        "
      >
        <div v-if="states.dining.menus.length" class="menu-list">
          <MenuItem
            v-for="(menu, index) in states.dining.menus"
            :key="'menu' + index"
            :menu="menu"
          />
        </div>
      </BottomModal>
    </transition>
    <transition name="moveTop">
      <div v-if="showBottomAction" class="bottom-action">
        <button @click="bookDining">餐廳訂位</button>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import DecoTitle from '@/components/decoTitle.vue'
import EventSwiper from '@/components/eventSwiper.vue'
import BottomModal from '@/components/bottomModal.vue'
import MenuItem from '@/components/menuItem.vue'
import bus from '@/assets/js/bus.js'
import api from '@/api/api'
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'Dining',
  components: { Header, DecoTitle, EventSwiper, BottomModal, MenuItem },
  data() {
    return {
      tabs: ['餐廳資訊', '用餐政策'],
      activeTab: 0,
      openMenu: false,
      showBottomAction: false,
    }
  },
  methods: {
    handleFixed(val) {
      this.showBottomAction = val
    },
  },
  computed: {
    storeId() {
      return this.$route.params.sid || '0'
    },
  },
  created() {
    localStorage.setItem('activeTab', 1)

    bus.on('clickTab', index => {
      this.activeTab = index
      if (!index) this.activeChip = ''
    })
    bus.on('showMenu', () => {})
  },
  beforeUnmount() {
    bus.all.clear()
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const states = reactive({
      dining: computed(() =>
        store.state.selectedDiningList.find(
          dining => dining.id == route.params.did
        )
      ),
      eventList: [],
    })
    if (!states.dining) {
      router.replace({ name: 'Store', params: { sid: route.params.sid } })
    }
    api
      .ActivityList({
        hotelId: route.params.sid,
        pageType: 5,
      })
      .then(result => {
        states.eventList = result.data.slice(0, 7)
      })
      .catch(err => {
        console.error('取得活動失敗', err)
      })

    const actions = [
      {
        actionName: '美饌線上預訂',
        action: () => {
          const inleinTokenInfo = store.state.inleinTokenInfo
          const url = states.dining.reservationUrl
          const link = url.includes('inline')
            ? `${url}&pre_filled_form=${inleinTokenInfo}`
            : url
          window.openUrlExternal(link)
        },
      },
    ]

    return { states, actions }
  },
}
</script>

<style lang="scss" scoped>
.dining-content {
  padding: 32px 5.33% 99px;
  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.32px;
    color: #ceb17d;
  }
  .desc {
    padding: 16px 0;
  }
}
.event-section {
  background-color: #fff;
}
.menu-list {
  padding-top: 3px;
}
.bottom-action {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 19px 12.8%;
  background-color: #fff;
  button {
    max-width: none;
    width: 100%;
  }
}
.moveTop-enter-active {
  animation: moveTop 0.5s;
}
.moveTop-leave-active {
  animation: moveTop 0.5s reverse;
}
@keyframes moveTop {
  0% {
    bottom: -10%;
    opacity: 0.3;
  }
  100% {
    bottom: 0%;
    opacity: 1;
  }
}
</style>
